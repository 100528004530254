<template>
  <Header/>
  <el-scrollbar class="layout-main-scrollbar">
    <!-- 装饰 -->
    <div class="decorate" v-if="isDecorate">
      <div class="gk-container">
        <div class="title">装饰主页</div>
        <div class="cont">
          <div class="left">
            <img :src="background_img" alt="主页">
          </div>
          <div class="right">
            <div class="tit">个人主页封面大图</div>
            <div class="desc">推荐尺寸1920x300，JPG / GIF / PNG，RGB模式，3M以内。</div>
            <div class="tips">审核通过后展示</div>
            <el-upload :action="upload_url" :headers="headers" :limit="1" :on-success="handleImgSuccess" :show-file-list="false">
              <div class="btn-upload">上传图片</div>
            </el-upload>
          </div>
        </div>
        <div class="btns">
          <div class="btn btn-cancel" @click="backgroundImgCancel">取消保存</div>
          <div class="btn btn-save" @click="backgroundImgSave">保存设置</div>
        </div>
      </div>
    </div>
  <!--内容-->
  <div class="page-my-home">   
    <div class="banner">
      <img class="banner-img" :src="old_background_img" alt="">
      <div class="setmod">
        <el-dropdown placement="bottom" trigger="click" @command="handleDropdown">
          <div class="arrowMod">
            <img src="/static/images/icon-dropdown.png" alt="">
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="zs">装饰主页</el-dropdown-item>
              <el-dropdown-item command="trsj">他人视角</el-dropdown-item>
              <el-dropdown-item command="fx">分享主页</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <div class="main">
      <div class="gk-container-big">
        <!--  作者信息 -->
        <div class="my-info">
          <div class="left">
            <el-image :src="account.avatar" lazy>
              <template #error>
                <el-image src="/static/upload/tx2.png" lazy></el-image>
              </template>
            </el-image>
            <div class="info">
              <div class="name">{{ account.nickname }}</div>
              <div class="intro">{{ account.residecity }} ｜ {{ account.profession }}</div>
              <div class="static">
                <div class="item" v-for="(m,index) in authorStatistics" :key="index"><span>{{ m.name }}</span><i>{{ m.num }}</i></div>
              </div>
            </div>
          </div>
          <a href="/publish">
            <div class="btn-upload">+ 上传作品</div>
          </a>
        </div>
        <!----->
        <div class="gk-clear">
          <div class="my-home-side-bar">
            <div class="ikey-auth-mod" @click="onAuth">
              <img src="/static/images/icon-ikey-auth.png" alt="">
              <div class="cont">
                <div class="tit">点此申请ikeyhub认证</div>
                <div class="desc">获得官方认证，解锁专属内容</div>
              </div>
            </div>
            <div class="side-bar-cont">
              <div class="side-bar-btns">
                <router-link v-for="(m,index) in user_nav" :key="index" :to="m.url" class="item" :class="m.class" active-class="active">
                  <div>
                    <img :src="m.icon" alt=""><span>{{m.name}}</span>
                  </div>
                </router-link>
              </div>
              <!--最近访客-->
              <div class="module-visitor">
                <div class="tit">最近访客</div>
                <ul>
                  <li v-for="(m,index) in userVisitor" :key="index">
                    <el-image :src="m.visitor_user_avatar" lazy>
                      <template #error>
                        <el-image src="/static/upload/tx2.png" lazy></el-image>
                      </template>
                    </el-image>
                    <span>{{ m.visitor_nickname }}</span>
                    <i>{{ m.show_time }}</i>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <router-view />

        </div>
      </div>
    </div>
  </div>
  <Footer/>
  </el-scrollbar>
</template>

<script>
//引入组件
import Header from "@/layout/components/header";
import Footer from "@/layout/components/footer";
import user from "@/api/user";
import {mapState} from "vuex";
import {getToken} from "@/utils/auth";
import attachment from "@/api/public";

export default {
  name: 'user',
  components: {Header, Footer},
  computed: {
    ...mapState(['account'])
  },
  data() {
    return {
      upload_url: '',
      background_img:'/static/images/user-banner.png',
      old_background_img:'/static/images/user-banner.png',
      headers: {"Authorization": getToken()},
      user_nav:[
        {name:'我的作品',url:'/user/home',class:'',icon:'/static/iconImg/icon-work.png'},
        {name:'收藏夹',url:'/user/folder',class:'threeWord',icon:'/static/iconImg/icon-folder.png'},
        {name:'我的关注',url:'/user/focus',class:'',icon:'/static/iconImg/icon-focus.png'},
        {name:'个人资料',url:'/user/account_info',class:'',icon:'/static/iconImg/icon-accout-set.png'},
        //{name:'活动中心',url:'',checked:false,class:'',icon:'/static/iconImg/icon-activity.png'},
      ],
      authorStatistics:[],
      userVisitor:[],
      isDecorate:false
    }
  },
  created() {
    attachment.uploadFileUrl().then(res => {
      this.upload_url = res;
    });
    this.getBackgroundImg();
    this.getAuthorStatistics();
  },
  mounted() {
    this.getUserVisitor();
  },
  methods: {
    //下拉按钮事件
    handleDropdown(e){
      if(e==='zs'){
        this.isDecorate = true;
      } else if(e==='trsj'){
        this.$router.push('/utterer/'+this.account.uid);
      } else if(e==='fx'){
        this.$message.success({
          message: '三方权限申请中，稍后更新功能！'
        });
      }
    },
    //上传封面图
    handleImgSuccess(res) {
      console.log(res);
      if (res.code == 0) {
        this.background_img = res.data.url;
        this.$message.success({
          message: '上传文件成功！'
        });
      } else {
        this.$message.error({
          message: res.msg
        });
      }
    },
    //封面取消保存
    backgroundImgCancel(){
      this.background_img = this.old_background_img;
      this.isDecorate = false;
    },
    //保存封面
    backgroundImgSave(){
      user.bindBackgroundImg({
        back_img:this.background_img
      }).then(res=>{
            if (res.code == 0) {
              this.old_background_img = this.background_img;
              this.$message.success({
                message: '图片保存成功！'
              });
              this.isDecorate = false;
            } else {
              this.$message.error({
                message: res.msg
              });
            }
      })
    },
    //获取 用户主页图片
    getBackgroundImg(){
      user.getBackgroundImg({
        uid:this.account.uid
      }).then(res=>{
        if (res.code == 0) {
          this.background_img = res.data.background_img;
          this.old_background_img = res.data.background_img;
        } else {
          this.$message.error({
            message: res.msg
          });
        }
      })
    },
    //获取用户访客记录
    getUserVisitor(){
      user.getUserVisitor({
        uid: 0,
        pagesize:4
      }).then(res => {
        if (res) {
          this.userVisitor = res.data;
        } else {
          console.log(res);
        }
      })
    },
    //调取 用户 作品，关注，收藏 统计
    getAuthorStatistics() {
      this.loading = true
      user.getAuthorStatistics({
        uid: 0
      }).then(res => {
        if (res) {
          this.authorStatistics = res.data;
        } else {
          console.log(res);
        }
      })
    },
    // 跳转至认证页面
    onAuth(){
      this.$router.push({
            name:'authentication'
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.ikey-auth-mod{width:100%;height:118px;margin-bottom:20px; border-radius: 10px;cursor: pointer; background: #FFFFFF;display: flex;justify-content: center;align-items: center;
  img{width:80px;margin-right:10px;}
  .tit{margin-bottom:5px;font-size: 16px;font-weight: 500;line-height: 22px;color: #333333;}
  .desc{font-size: 14px;font-weight: normal;color: #666666;}
}
.decorate{position:relative;top:100px;padding:50px 0 ;
  .title{width:1024px;margin-bottom:30px;color: #333333;font-size: 24px;font-weight: bold;line-height: 24px;text-align: center;}
  .cont{display: flex;align-items: center;margin-bottom:20px;
    .left{margin-right:30px;height:160px;
      img{display: block;width:1024px;height:160px;}
    }
    .right{width:200px;line-height:22px;
      .tit{margin-bottom:5px;font-size: 18px;font-weight: 500;color: #333333 }
      .desc{font-size: 14px;color:#666;}
      .tips{font-size: 14px;color:#FD5353;}
      .btn-upload{width: 124px;height: 44px;line-height:44px;margin-top:5px;text-align:center;border-radius: 6px;background: #FFFFFF;border: 1px solid #CCCCCC;color: #666666;font-size: 16px;font-weight: 500;}
    }
  }
  .btns{width:1024px;display: flex;align-items:center;justify-content: center;}
  .btns .btn{width: 152px;height: 48px;line-height: 48px;border-radius: 6px;text-align: center;font-size: 18px;font-weight: 500;cursor:pointer;
    &.btn-cancel{background: #C1F421;color: #000000;margin-right:30px;}
    &.btn-save{background: #000000;color: #C1F421;}
  }
}
.page-my-home .main .my-home-side-bar .side-bar-btns .item:hover{
  background: #F7F7F7;
}
.layout-container {
  height: 100%;
  width: 100%;
}
.layout-main-scrollbar {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: calc(100vh - 100px);
}
.content-wrapper {
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.banner{
  .setmod{position:absolute;top:100px;left:50%;margin-left:692px;
    .arrowMod{width:64px;height: 30px;background: rgba(0, 0, 0, 0.4);border-radius: 232px;display:flex;align-items:center; justify-content: center;
      img{height:9px;}
    }
  }
}
</style>
