<template>
  <!--footer 开始-->
  <div class="footer bg-fff" :class="auth?'bg-gray':''">
    <div class="gk-container">
      <div class="info">
        <div class="gk-clear">
          <div class="footer-nav">
            <div class="mod" v-for="(m,index) in footer_nav" v-bind:key="index">
              <h3>{{m.name}}</h3>
              <router-link v-for="(n,index) in m.children" v-bind:key="index" :to="n.url">{{n.name}}</router-link>
            </div>
          </div>
          <div class="footer-code">
            <div class="mod">
              <img :src="config_company.company_wechat" alt="">
              <h4>咋办公众号</h4>
              <span>每天看一篇活动资讯</span>
            </div>
            <div class="mod">
              <img :src="config_company.company_wechat_app" alt="">
              <h4>下载咋办APP</h4>
              <span>最热活动资源享不停</span>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <p>Copyright © 2023-{{ new Date().getFullYear() }} {{config_company.company_name_zh}}. All Rights Reserved.</p>
        <p><a href="https://beian.miit.gov.cn" target="_blank">{{config_base.icp}}</a></p>
      </div>
    </div>
  </div>
  <!--footer 结束-->
  <!-- 返回顶部 -->
  <div class="backTop" @click="scrollToTop" v-show="showBackToTop">
    <img src="@/assets/images/icon-backtop.png" alt="">
    <span>回到顶部</span> 
  </div>
</template>

<script>

import sys from "@/api/system";

export default {
  name: 'footer',
  components: {},
  props:{
    auth:{ //是否认证页（认证页面的footer背景色不同）
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      footer_nav:[],
      config_base:[],
      config_company:[],
      showBackToTop:false, // 返回顶部按钮显示or隐藏
    }
  },
  
  created() {
    this.getNavFooterList();
    this.getConfig();
    window.addEventListener('scroll',this.handleScroll);  // 监听滚动事件
  },
  unmounted(){
    window.removeEventListener('scroll',this.handleScroll) // 销毁组件时移除事件监听
  },
  methods: {
    //enter键提交
    keyDown(e) {
      //console.log('enter');
      if (e.keyCode == 13 || e.keyCode == 100) {
        this.login();
      }
    },
    //导航调取
    getNavFooterList() {
      sys.getNavFooterList({
        type:'pc'
      }).then(res=>{
        if (res) {
          this.footer_nav = res.data;
        }else{
          console.log(res);
        }
      })
    },
    //导航调取
    getConfig() {
      sys.getConfig({
        param:'no'
      }).then(res=>{
        if (res) {
          this.config_base = res.data.base;
          this.config_company = res.data.company;
        }else{
          console.log(res);
        }
      })
    },
    scrollToTop(){
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    handleScroll(){
      if(window.scrollY > 200){ //当滚动超过300px时显示返回顶部按钮
        this.showBackToTop = true
      } else{
        this.showBackToTop = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.footer {text-align: center; font-size: 12px; color: #999; width: 100%;
  .copyright { padding: 20px; }
  &.bg-gray{background: #F7F7F7;}
}
.backTop{position: fixed;bottom:20px;right:20px;background: #C1F421;color:#000;padding:10px;cursor: pointer;
  img{display:block;width:30px;height:30px;margin:0 auto 3px;}
  span{display: block;}
}
</style>
